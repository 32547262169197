import React from 'react'
import './index.css'

import { Link } from 'react-router-dom';

class BlogNote extends React.Component {
  render() {
    if (this.props.deactivate) {
      return (
        <div className={"BlogNoteContDeac"}>
          <div className={"BlogNoteDeact"}>
          </div>
          <div className={"BlogNoteTextDeact"}>
            {this.props.article.title()}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"BlogNoteCont"}>
          <div className={"BlogNote"}>
          </div>
          <Link to={this.props.article.route()} className={"BlogNoteText"}>
            {this.props.article.title()}
          </Link>
        </div>
      );
    }
  }
}

export default BlogNote;
