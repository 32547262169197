import React from 'react';
import Article from '../article.js';

class ProblemsVsSolutions {
  title() {
    return "Problems vs Solutions"
  }

  route() {
    return 'problems_vs_solutions'
  }

  summary() {
    return "something about problems and solutions"
  }

  content() {
    return (
      <div>
        Problems vs Solutions
      </div>
    );
  }
}

export default ProblemsVsSolutions;