import React from 'react';

class TodoPage extends React.Component {
  render() {
    return (
      <div>
        <h1>
          Things to do on the Website
        </h1>
        <ul>
          <li>
            Actually write all of the articles
          </li>
          <li>
            Tweak text to be more readable in articles
          </li>
          <li>
            Add footer for social handles and sharing throughout the website
          </li>
          <li>
            Add interactive experiences to blog like taking notes and things
          </li>
          <li>
            Add TODO list feature to website
          </li>
          <li>
            Smooth transition from App to blog to website to extension 
          </li>
        </ul>
      </div>
    );
  }
}

export default TodoPage;
