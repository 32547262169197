import React from 'react';
import { useMediaQuery } from 'react-responsive'

import ScrollSuggestion from '../ScrollSuggestion'

import TitleDesktop from '../TitleDesktop'
import TitleMobile from '../TitleMobile'

import './index.css';

function Splash(props) {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 900px)'
  });

  return (
    <div className={"Splash"}>
      {isBigScreen && <TitleDesktop />}
      {isTabletOrMobile && <TitleMobile />}
      {props.readMore &&
        <div className={"SplashSuggestion"}>
          <ScrollSuggestion />
        </div>
      }
    </div>
  );
}

export default Splash;