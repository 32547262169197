import React from 'react';

import ArticlePage from './components/ArticlePage'
import BlogPage from './components/BlogPage'
import LandingPage from './components/LandingPage'
import ErrorPage from './components/ErrorPage'
import TodoPage from './components/TodoPage'

import ScrollToTop from './components/ScrollToTop'

import articles from './data/registry'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

class App extends React.Component {
  defineRoutes() {
    return articles.map((x, idx) => 
      <Route exact path={'/' + x.route()}
             render={(props) =>
               <BlogPage article={x} idx={idx} {...props} />
             }/>
    )
  }
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" render={(props) => <LandingPage {...props} />} />
          {this.defineRoutes()}
          <Route exact path="/todo" render={(props) => <TodoPage /> } />
          <Route render={(props) => <ErrorPage {...props} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
