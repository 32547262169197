import React from 'react';
import { useMediaQuery } from 'react-responsive'

import './index.css'

import BlogArticles from '../BlogArticles'
import Splash from '../Splash'

import articles from '../../data/registry';

function LandingPage() {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 900px)'
  });

  return (
    <div className={"LandingPage"}>
      {isBigScreen &&
        <div className={"LandingPageCont"}>
          <div className={"LandingPageContArticles"}>
            <BlogArticles articles={articles}/>
          </div>
          <div className={"LandingPageContSplash"}>
            <Splash readMore={false} />
          </div>
        </div>
      }
      {isTabletOrMobile &&
        <div>
          <div className={"LandingPageContAltSplash"}>
            <Splash readMore={true}/>
          </div>
          <BlogArticles articles={articles}/>
        </div>
      }
    </div>
  );
}

export default LandingPage;

