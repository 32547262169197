import React from 'react';
import Article from '../article.js';

class MovingGoalPosts {
  title() {
    return "Moving Goal Posts"
  }

  route() {
    return 'moving_goal_posts'
  }

  summary() {
    return "something about moving the goalposts"
  }

  content() {
    return (
      <div>
        Moving Goal Posts
      </div>
    );
  }
}

export default MovingGoalPosts;