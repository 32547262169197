import React from 'react'
import './index.css'

import left_carret from './assets/left_carret.svg';

import articles from '../../data/registry';

import { Link } from 'react-router-dom';

class ArticlePage extends React.Component {

  renderPrevious() {
    const idx = this.props.idx - 1
    if (idx >= 0) {
      return (
        <Link className={"ArticlePageBack"} to={"/" + articles[idx].route()}>
          <img src={left_carret} />  {articles[idx].title()}
        </Link>
      )
    }
  }

  renderNext() {
    const idx = this.props.idx + 1
    if (idx < articles.length) {
      return (
        <Link className={"ArticlePageBack"} to={"/" + articles[idx].route()}>
          {articles[idx].title()} <img src={left_carret} className={"ArticlePageFlip"} />
        </Link>
      ) 
    }
  }

  renderNavigation() {
    return (
      <div className={"ArticleContentNavigation"}>
        <div className={"ArticleContentNavigationBar"}>
          {this.renderPrevious()}
        </div>
        <div className={"ArticleContentNavigationBar"}>
          {this.renderNext()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"ArticleContent"}>
        <div className={"ArticlePageContent"}>
          {this.props.navigation ??
            this.renderNavigation()
          }
          <div className={"ArticlePageTitle"}>
            {this.props.article.title()}
          </div>
          <div>
            {this.props.article.content()}
          </div>
          {this.props.navigation ??
            this.renderNavigation()
          }
        </div>
      </div>
    );
  }
}

export default ArticlePage;
