import React from 'react'
import './index.css'

class Paragraph extends React.Component {
  render() {
    return (
      <div className={"Paragraph"}>
        {this.props.text}
      </div>
    );
  }
}

export default Paragraph;
