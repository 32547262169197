class Article {
  route() {
  	console.error("Unimplemented Error");
  }

  title() {
    console.error("Unimplemented Error");
  }

  summary() {
    console.error("Unimplemented Error");
  }

  content() {
    console.error("Unimplemented Error");
  }
}

export default Article;