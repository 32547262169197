import React from 'react';
import Article from '../article.js';

class FrictionProvidesTraction {
  title() {
    return "Friction Provides Traction"
  }

  route() {
    return 'friction_provides_traction'
  }

  summary() {
    return "something about friction providing traction"
  }

  content() {
    return (
      <div>
        Friction Provides Traction
      </div>
    );
  }
}

export default FrictionProvidesTraction;