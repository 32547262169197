import React from 'react';
import Article from '../article.js';

class WinningAndLosing {
  title() {
    return "Winning and Losing"
  }

  route() {
    return 'winning_and_losing'
  }

  summary() {
    return "something about winning and losing"
  }

  content() {
    return (
      <div>
        Winning and Losing
      </div>
    );
  }
}

export default WinningAndLosing;