import React from 'react';
import Article from '../article.js';

class LessonsFromAVideoGame {
  title() {
    return "Lessons from a Video Game"
  }

  route() {
    return 'lessons_from_a_video_game'
  }

  summary() {
    return "something about video game"
  }

  content() {
    return (
      <div>
        Lessons from a Video Game
      </div>
    );
  }
}

export default LessonsFromAVideoGame;