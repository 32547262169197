import FalsePromises from './articles/false_promises';
import Interfaces from './articles/interfaces';
import GoodDecisionsGoodResults from './articles/good_decisions_good_results';
import LessonsFromAVideoGame from './articles/lessons_from_a_video_game';
import ProblemsVsSolutions from './articles/problems_solutions';
import SecurityAndPrivacy from './articles/security_and_privacy';
import MovingGoalPosts from './articles/moving_goal_posts';
import BeMoreIrrational from './articles/be_more_irrational';
import FrictionProvidesTraction from './articles/friction_provides_traction';
import WinningAndLosing from './articles/winning_and_losing';

const articles = [
  new FalsePromises(),
  new Interfaces(),
  new GoodDecisionsGoodResults(),
  new LessonsFromAVideoGame(),
  new ProblemsVsSolutions(),
  new SecurityAndPrivacy(),
  new MovingGoalPosts(),
  new BeMoreIrrational(),
  new FrictionProvidesTraction(),
  new WinningAndLosing()
];

export default articles