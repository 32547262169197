import React from 'react';
import Article from '../article.js';

import Paragraph from '../components/Paragraph';

class FalsePromises extends Article {
  title() {
    return "False Promises"
  }

  route() {
    return 'false_promises'
  }

  summary() {
    return "something about false promises"
  }

  content() {
    return (
      <div>
        <Paragraph text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat leo. Phasellus porta dui non orci lacinia, id vehicula lorem pulvinar. Morbi in arcu id mauris consequat pretium. Praesent vulputate mattis viverra. Maecenas congue a felis at porta. Cras mattis egestas turpis. In et viverra sapien, eu cursus lacus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam eget augue ac sapien consequat aliquet. Sed finibus fringilla imperdiet."} />
        <Paragraph text={"Ut dui augue, porttitor eget dui ut, egestas ullamcorper odio. Integer sed neque nibh. Mauris finibus condimentum nunc non aliquet. Sed ac purus purus. Vestibulum gravida semper sem, vitae vestibulum ante dapibus quis. Nunc rhoncus eros ac nisl sodales, sit amet interdum nisl posuere. Cras tincidunt fringilla augue, et auctor ipsum accumsan nec. Vestibulum facilisis ante libero, id ultrices felis commodo in. Donec pharetra, tortor nec efficitur maximus, purus eros lacinia diam, sit amet mollis tellus risus vitae ex."} />
        <Paragraph text={"Vivamus vestibulum scelerisque eros id cursus. Nam eu dui pharetra, porttitor nisi vel, porttitor enim. Mauris condimentum massa et nisi faucibus, ac commodo turpis laoreet. Vestibulum eu sem diam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Ut non ullamcorper diam, vel condimentum metus. Vivamus iaculis odio et vulputate commodo. Nullam dignissim ut mi ac condimentum. Integer aliquam tellus in leo tincidunt ornare. Nam gravida, ipsum eget sollicitudin vulputate, est diam sodales augue, eu vehicula orci risus ut metus."} />
        <Paragraph text={"Proin porta suscipit lorem sed vulputate. Donec aliquet felis in orci viverra dictum. Aenean quis aliquam odio. Nam euismod convallis nunc. Nam eleifend, urna non scelerisque finibus, leo nibh facilisis lorem, imperdiet elementum est sem nec arcu. Vivamus tortor diam, faucibus id felis ut, lacinia condimentum nisi. Fusce malesuada posuere diam, a laoreet lacus bibendum auctor. Maecenas non velit ex. Aenean aliquam condimentum feugiat. Nunc blandit ultrices nisl ut pharetra. Quisque non leo ac augue lacinia luctus. Vivamus iaculis tortor ac sem vestibulum, id ullamcorper quam pulvinar. Praesent consectetur elementum magna at condimentum."} />
        <Paragraph text={"Vestibulum felis ipsum, tincidunt nec efficitur ac, pharetra non felis. Sed varius sagittis lacus vitae mattis. Ut blandit nec orci in elementum. Proin facilisis metus et aliquet vulputate. Aliquam mollis orci ac augue finibus lobortis. Suspendisse ullamcorper purus eget justo dignissim, vel gravida eros fermentum. Sed mattis libero urna, id dapibus urna venenatis at. Ut eu mauris nec mi viverra laoreet venenatis consectetur tellus. Duis sit amet lectus gravida nulla ullamcorper dignissim. Donec at ligula dui. Morbi suscipit fermentum erat at posuere. Vestibulum in ante massa. Donec gravida cursus tellus non tincidunt. Suspendisse pellentesque efficitur eros scelerisque vulputate. Integer rutrum sed quam dapibus sagittis. Praesent vehicula pharetra est quis luctus."} />
      </div>
    );
  }
}

export default FalsePromises;