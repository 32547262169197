import React from 'react'
import { useMediaQuery } from 'react-responsive'

import './index.css'

import ArticlePage from '../ArticlePage'
import BlogArticles from '../BlogArticles'

import articles from '../../data/registry';

function BlogPage(props) {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 899px)'
  });

  return (
    <div className={"BlogPage"}>
      {isBigScreen &&
        <div className={"BlogPageCont"}>
          <div className={"BlogPageContArticles"}>
            <BlogArticles articles={articles}
                          active={props.article}/>
          </div>
          <div className={"BlogPageContSplash"}>
            <ArticlePage navigation={false}
                         idx={props.idx}
                         article={props.article}/>
          </div>
        </div>
      }
      {isTabletOrMobile &&
        <div>
          <ArticlePage article={props.article}
                       idx={props.idx}/>
        </div>
      }
    </div>
  );
}

export default BlogPage;

