import React from 'react'
import './index.css'

import BlogNote from './BlogNote';

class BlogArticles extends React.Component {
  renderTitles() {
    return this.props.articles.map((x, id) => {
      if (this.props.active) {
        return (
          <BlogNote article={x}
                    deactivate={this.props.active.route() == x.route()} />
        );
      } else {
        return (<BlogNote article={x} />);
      }
    });
  }

  render() {
    return (
      <div className={"BlogArticles"}>
        <div className={"BlogArticlesContainer"}>
          <div className={"BlogPrimeMeridianContainer"}>
            <div className={"BlogPrimeMeridian"}>
            </div>
          </div>
          <div className={"BlogPrimeMeridianNoteContainer"}>
            {this.renderTitles()}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogArticles;
