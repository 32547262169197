import React, {useEffect} from 'react';
import {loadTimelineData} from 'anima-timeline';

import './index.css'

function App() {
  return <TitleMobile {...iPhone11ProMaxData} />;
}

export default App;

function TitleMobile(props) {
  const {
    mem,
    text1,
    tive,
    memTiv,
    memtive,
    memTiv2,
    memtive2,
    memTiv3,
    number,
    theArtOfDeconstru,
    number2,
    aDeterministAttitu,
    noun,
  } = props;

  useEffect(() => {
    loadTimelineData(group5TimelineData);
  }, []);

  return (
    <div className="group5 component component-wrapper not-ready">
      <div className="group-4-iXvpXI">
        <div className="group-PsP2at">
          <div className="memtive-HP2jxo">{memtive2}</div>
          <div className="mem-tiv-HP2jxo valign-text-middle">{memTiv3}</div>
        </div>
        <div className="group-3-PsP2at">
          <div className="number-FynGv4">{number}</div>
          <div className="the-art-of-deconstru-FynGv4">{theArtOfDeconstru}</div>
        </div>
        <div className="group-2-PsP2at">
          <div className="number-ZT5fTx">{number2}</div>
          <div className="a-determinist-attitu-ZT5fTx">{aDeterministAttitu}</div>
        </div>
        <div className="noun-PsP2at">{noun}</div>
      </div>
    </div>
  );
}

const group5TimelineData = [
  {
    initial_state_name: "keyframe1",
    root_element: ".group5",
    states_flow: {
      keyframe1: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".memtive-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-PsP2at": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe2",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".mem-tiv-HP2jxo": {
            bottom: "-118px",
            height: "60.87%",
            left: "29px",
            opacity: 0,
            width: "100%",
          },
          ".memtive-HP2jxo": {
            height: "49px",
            left: "29px",
            opacity: 0,
            top: "118px",
            width: "86.83%",
          },
          ".noun-PsP2at": {
            opacity: 0,
          },
          ".number-FynGv4": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe2: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".memtive-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-PsP2at": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe3",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".mem-tiv-HP2jxo": {
            bottom: "-118px",
            height: "60.87%",
            left: "29px",
            opacity: 0,
            width: "100%",
          },
          ".memtive-HP2jxo": {
            height: "49px",
            left: "29px",
            top: "118px",
            width: "86.83%",
          },
          ".noun-PsP2at": {
            opacity: 0,
          },
          ".number-FynGv4": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe3: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".memtive-HP2jxo": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-PsP2at": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe4",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".mem-tiv-HP2jxo": {
            bottom: "-118px",
            height: "60.87%",
            left: "29px",
            width: "100%",
          },
          ".memtive-HP2jxo": {
            height: "49px",
            left: "29px",
            top: "118px",
            width: "86.83%",
          },
          ".noun-PsP2at": {
            opacity: 0,
          },
          ".number-FynGv4": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe4: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-PsP2at": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe5",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".mem-tiv-HP2jxo": {
            bottom: "0px",
            height: "60.87%",
            width: "100%",
          },
          ".memtive-HP2jxo": {
            height: "49px",
            left: "0px",
            width: "86.83%",
          },
          ".noun-PsP2at": {
            opacity: 0,
          },
          ".number-FynGv4": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe5: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe6",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".number-FynGv4": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe6: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe7",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".number-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 0,
          },
        },
      },
      keyframe7: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-ZT5fTx": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-FynGv4": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe8",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 0,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 1,
          },
        },
      },
      keyframe8: {
        listeners: [],
        overrides: {
          ".a-determinist-attitu-ZT5fTx": {
            opacity: 1,
          },
          ".the-art-of-deconstru-FynGv4": {
            opacity: 1,
          },
        },
      },
    },
  },
];

const iPhone11ProMaxData = {
    mem: "mem",
    text1: "·",
    tive: "tive",
    memTiv: "/mem-tiv/",
    memtive: "mem·tive",
    memTiv2: "/mem-tiv/",
    memtive2: "mem·tive",
    memTiv3: "/mem-tiv/",
    number: "1",
    theArtOfDeconstru: "The art of deconstructing awareness",
    number2: "2",
    aDeterministAttitu: "A determinist attitude toward awareness",
    noun: "NOUN",
};