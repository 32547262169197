import React from 'react';
import Article from '../article.js';

class Interfaces {
  title() {
    return "Interfaces"
  }

  route() {
    return 'interfaces'
  }

  summary() {
    return "something about interfaces"
  }

  content() {
    return (
      <div>
        Interfaces
      </div>
    );
  }
}

export default Interfaces;