import React from 'react';
import Article from '../article.js';

class GoodDecisionsGoodResults extends Article {
  title() {
    return "Good Decisions ≠ Good Results"
  }

  route() {
    return 'good_decisions_good_results'
  }

  summary() {
    return "something about false promises"
  }

  content() {
    return (
      <div>
        Good Decisions ≠ Good Results
      </div>
    );
  }
}

export default GoodDecisionsGoodResults;