import React from 'react'
import './index.css'

import down from './assets/down.svg'

class ScrollSuggestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opacity: 1.0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const prop = 1 - (winScroll / document.documentElement.clientHeight)
    this.setState({
      opacity: prop
    })
  }

  render() {
    return (
      <div className={"ScrollSuggestion"}
           style={{
             opacity: this.state.opacity
           }}>
        <div className={"ScrollSuggestionText"}>
          READ MORE
        </div>
        <div>
          <img src={down} />
        </div>
      </div>
    );
  }
}

export default ScrollSuggestion;
