import React from 'react';
import Article from '../article.js';

class BeMoreIrrational extends Article {
  title() {
    return "Be More Irrational"
  }

  route() {
    return 'be_more_irrational'
  }

  summary() {
    return "something about being more irrational"
  }

  content() {
    return (
      <div>
        Be More Irrational
      </div>
    );
  }
}

export default BeMoreIrrational;