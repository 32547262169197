import React from 'react';

import { Link } from 'react-router-dom';

import './index.css'

class ErrorPage extends React.Component {
  render() {
    return (
      <div className={"ErrorPage"}>
        <div>
          <div className={"ErrorCode"}>
            404
          </div>
          <div className={"ErrorMessage"}>
            Page doesn't exist, sorry
          </div>
          <Link to="/">Back Home?</Link>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
