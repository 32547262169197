import React, {useEffect} from 'react';
import {loadTimelineData} from 'anima-timeline';

import './index.css'

function App() {
  return <AppleTV {...appleTVData} />;
}

export default App;

function AppleTV(props) {
  const {
    mem,
    text1,
    tive,
    memTiv,
    mem2,
    text2,
    tive2,
    memTiv2,
    mem3,
    text3,
    tive3,
    memTiv3,
    number,
    number2,
    memTiv4,
    mem4,
    text4,
    tive4,
    theArtOfDeconstru,
    aDeterministAttitu,
    noun,
  } = props;

  useEffect(() => {
    loadTimelineData(groupTimelineData);
  }, []);

  return (
    <div className="group component component-wrapper not-ready">
      <div className="number-3xxWxY">{number}</div>
      <div className="number-bq1j6S">{number2}</div>
      <div className="mem-tiv-3xxWxY valign-text-middle">{memTiv4}</div>
      <div className="group-2-3xxWxY">
        <div className="mem-w53x01">{mem4}</div>
        <div className="text-4-w53x01">{text4}</div>
        <div className="tive-w53x01">{tive4}</div>
      </div>
      <div className="the-art-of-deconstru-3xxWxY">{theArtOfDeconstru}</div>
      <div className="a-determinist-attitu-3xxWxY">{aDeterministAttitu}</div>
      <div className="noun-3xxWxY">{noun}</div>
    </div>
  );
}

const groupTimelineData = [
  {
    initial_state_name: "keyframe1",
    root_element: ".group",
    states_flow: {
      keyframe1: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-w53x01": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".text-4-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe2",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            opacity: 0,
            top: "158px",
          },
          ".mem-w53x01": {
            opacity: 0,
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".text-4-w53x01": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
          ".tive-w53x01": {
            opacity: 0,
          },
        },
      },
      keyframe10: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe11",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe11: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe12",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 1,
          },
        },
      },
      keyframe12: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".mem-w53x01": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".text-4-w53x01": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 0,
                easing: "linear",
              },
            },
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 1,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 1,
          },
        },
      },
      keyframe2: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".text-4-w53x01": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe3",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            opacity: 0,
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".text-4-w53x01": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
          ".tive-w53x01": {
            opacity: 0,
          },
        },
      },
      keyframe3: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe4",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            opacity: 0,
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
          ".tive-w53x01": {
            opacity: 0,
          },
        },
      },
      keyframe4: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe5",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            opacity: 0,
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe5: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe6",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe6: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe7",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            left: "187px",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            left: "187px",
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe7: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe8",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe8: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".group-2-3xxWxY": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".mem-tiv-3xxWxY": {
                delay: 0,
                duration: 500,
                easing: "ease-in-out",
              },
              ".noun-3xxWxY": {
                delay: 200,
                duration: 500,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe9",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            top: "122px",
            width: "32.43%",
          },
          ".mem-tiv-3xxWxY": {
            top: "158px",
          },
          ".noun-3xxWxY": {
            opacity: 0,
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
      keyframe9: {
        listeners: [
          {
            animations: {
              ".a-determinist-attitu-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".number-bq1j6S": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".the-art-of-deconstru-3xxWxY": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
              ".tive-w53x01": {
                delay: 0,
                duration: 200,
                easing: "ease-in-out",
              },
            },
            change_to_state: "keyframe10",
            delay: 0,
            listener_type: "timer",
          },
        ],
        overrides: {
          ".a-determinist-attitu-3xxWxY": {
            opacity: 0,
          },
          ".group-2-3xxWxY": {
            height: "16.72%",
            width: "32.43%",
          },
          ".number-3xxWxY": {
            opacity: 0,
          },
          ".number-bq1j6S": {
            opacity: 0,
          },
          ".the-art-of-deconstru-3xxWxY": {
            opacity: 0,
          },
        },
      },
    },
  },
];

const appleTVData = {
    mem: "mem",
    text1: "·",
    tive: "tive",
    memTiv: "/mem-tiv/",
    mem2: "mem",
    text2: "·",
    tive2: "tive",
    memTiv2: "/mem-tiv/",
    mem3: "mem",
    text3: "·",
    tive3: "tive",
    memTiv3: "/mem-tiv/",
    number: "1",
    number2: "2",
    memTiv4: "/mem-tiv/",
    mem4: "mem",
    text4: "·",
    tive4: "tive",
    theArtOfDeconstru: " The art of deconstructing awareness",
    aDeterministAttitu: " A determinist attitude toward awareness",
    noun: "NOUN",
};