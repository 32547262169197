import React from 'react';
import Article from '../article.js';

class SecurityAndPrivacy {
  title() {
    return "Security and Privacy"
  }

  route() {
    return 'security_and_privacy'
  }

  summary() {
    return "something about security and privacy"
  }

  content() {
    return (
      <div>
        Security and Privacy
      </div>
    );
  }
}

export default SecurityAndPrivacy;